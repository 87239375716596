@import '../../../../ConstantsAndMixins.scss';

.view-friends-modal {
    .view-list-friends {
        min-width: 300px;
        max-height: 400px;
        overflow: auto;

        .friend {
            @include tileStyles;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .profile-info {
                display: flex;
                align-items: center;

                .profile-pic {
                    margin-right: 1rem;
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }
}