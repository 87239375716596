@import '../../ConstantsAndMixins.scss';

$header-padding: 12px;
$animation-speed: 0.8s;
$animation-curve: cubic-bezier(0.58, 0.08, 0.26, 0.88);
$transition: all $animation-speed $animation-curve;

@mixin absoluteCenter() {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(2);
}

@mixin absoluteTopLeft() {
    top: 0;
    bottom: 0;
    left: $header-padding;
    transform: translate(0%, 0%) scale(1);
}

.header {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 100;
    padding: 0 $header-padding;
    box-sizing: border-box;
    background-color: black;
    transition: $transition;
    display: flex;
    align-items: center;

    .logo-wrapper {
        transition: $transition;
        position: absolute;
        transform-origin: center;
        display: flex;
        align-items: center;

        @include absoluteCenter();

        text {
            user-select: none;
        }

        &.can-click {
            cursor: pointer;
        }
    }

    &.header-mode {
        height: $header-height;

        .logo-wrapper {
            @include absoluteTopLeft();
        }
    }

    .back-icon {
        color: white;
    }

    .settings-icon {
        padding-right: 0;
    }

    .action-button {
        display: block;
        margin-left: auto;

        opacity: 0;
        @include fadeInAnimation($animation-speed);

        svg {
            color: white;
        }

        &.refresh-button {
            display: flex;
            margin-right: $header-padding;

            svg {
                color: black;
                margin-right: 4px;
            }
        }
    } 
}