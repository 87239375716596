@import '../../../ConstantsAndMixins.scss';

$filter-width: 10rem;

.search-wrapper {
    display: flex;
    position: relative;

    .MuiOutlinedInput-notchedOutline {
        border-color: rgb(190, 190, 190);
    }

    .list-type-picker {
        height: 40px; // match search height
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        width: $filter-width;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 1rem;

        .MuiOutlinedInput-notchedOutline {
            border-top-color: transparent;
            border-bottom-color: transparent;
            border-left-color: transparent;
        }

        .MuiSelect-root {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 0.8rem;
            padding-top: 0;
            padding-bottom: 0;

            svg {
                font-size: 1rem;
                margin-right: 8px;
            }
        }

    }

    .search {
        flex-grow: 1;

        .search-text-field {  
            margin: 0;

            .search-placeholder {
                display: flex;
                align-items: center;
                color: black;
        
                .MuiSvgIcon-root {
                    padding-right: $list-icon-padding;
                    height: 1rem;
                    width: 1rem;
                }
            }

            .MuiButtonBase-root {
                color: black;
            }

            .MuiInputBase-root {
                background-color: white;

                input {
                    padding-left: $filter-width;
                }
            }

            .Mui-disabled {
                .search-placeholder, .MuiSvgIcon-root {
                    color: $gray;
                }
            }

            .MuiOutlinedInput-root {
                border-radius: 10px;
            }
        }
    }
}

.search-option {
    display: flex;
    align-items: center;
    height: 100px;
    width: 100%;
    font-family: 'Nunito';
    overflow: hidden;

    @include smallScreen {
        font-size: 12px;
    }

    .poster {
        max-height:100%; 
        max-width:100%;
        margin-right: 1rem;
        border-radius: 8px;
    }

    .title {
        margin-right: 1rem;
    }
}

.list-type-option {
    svg {
        margin-right: 0.5rem;
    }
}

.MuiAutocomplete-paper {
    border: solid 2px black;
    border-top: 0;
}