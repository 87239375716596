@import '../../../ConstantsAndMixins.scss';

.email-verification-page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include smallScreen {
        margin-top: 4rem; // Height is auto on small screen so we can't rely on height: 100% to center it
    }

    .icons {
        display: flex;
    }

    .message {
        margin-top: 0.5rem;
    }

    .email-address {
        font-weight: bold;
        margin-bottom: 1rem;
    }

    .send-again-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        button {
            width: 8rem;
        }

        .spam-warning {
            margin-top: 5px;
            font-size: 0.9rem;
        }
    }
}