@import '../../../ConstantsAndMixins.scss';

.settings-modal {
    min-width: 200px;
    max-width: 400px;

    .settings {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include fadeInAnimation;

        .MuiButtonBase-root, .MuiTextField-root, .error-message {
            margin-bottom: 1rem;
        }

        .error-message {
            color: $red;
            font-family: 'Nunito';
        }

        .about {
            text-align: center;

            .about-section {
                margin-bottom: 1rem;

                &:first-child, a {
                    font-size: 1.15rem;
                }

                a {
                    margin: 0 0.5rem;
                }
            }

            .signature {
                margin-top: 2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                font-weight: bold;
                font-size: 1.25rem;

                .profile-pic {
                    height: 80px;
                    width: 80px;
                    margin-left: 1rem;
                }
            }
        }
    }
}