.share-modal {
    .modal-content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .copy-button {
            margin-right: 1rem;
        }
    }

    .copy-message {
        width: 100%;
        text-align: center;
        margin-top: 1rem;
        font-weight: bold;
    }
}