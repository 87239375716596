@import '../../ConstantsAndMixins.scss';

$sticky-header-height: 5.5rem;

.home-page, .view-list-page {
    display: flex;
    height: 100%;
    width: 100%;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    .upper {
        height: $home-upper-height;
        width: 100%;
        background-color: white;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        background-color: white;

        .profile, .suggestions {
            width: 50%;
        }
    }

    .lower {
        display: flex;
        background-color: $light-gray;
        height: calc(100% - #{$home-upper-height});

        .left, .right {
            height: 100%;
            width: 50%;
            padding: 1rem;
            box-sizing: border-box;
            overflow-y: auto;
            background-color: $light-gray;
        }

        // Apply padding to inner components for scroll behind sticky header
        .left {
            padding: 0;
        }

        .right {
            border-top: solid 2px black;

            @include smallScreen {
                border-top: none;
            }
        }

        .sticky-header {
            position: -webkit-sticky; /* Safari */ 
            position: sticky;
            padding-bottom: 0.5rem;
            top: 0;
            background-color: $light-gray;
            z-index: 1;
            display: flex;
            flex-direction: column;
            height: $sticky-header-height;
            box-shadow: grey 6px -2px 20px 1px;

            .search-wrapper {
                margin-top: 0.5rem;
                padding: 0 1rem;
            }
        }

        .list-wrapper {
            margin: 1rem;

            .top-tiles {
                position: absolute;
                z-index: -1;
                opacity: 0;
                top: 0;
                left: 0;
                padding: 1rem;
                box-sizing: border-box;
                background-color: black;
                width: 100%;

                &.show {
                    opacity: 1;
                }

                .top-tiles-header {
                    margin-bottom: 0.5rem;
                    text-align: center;

                    .logo-screenshot {
                        height: 70px;
                    }

                    .top-tiles-profile-pic {
                        height: 62px;
                        width: 62px;
                        border-radius: 50%;
                        padding: 4px 0;
                        margin-left: 1rem;
                        object-fit: cover;
                    }
                }

                .movie-tile {
                    margin-bottom: 0;
                    width: 100%;
                    max-width: 500px;
                    box-shadow: none; // fixes bug on Safari iOS

                    .rank {
                        font-size: 2rem;
                    }
                }

                // use spacers for top tiles since html-to-image isn't respecting margin
                .spacer {
                    width: 100%;
                    height: 0.5rem;
                }

                .top-tiles-footer {
                    margin-top: 1rem;
                    text-align: center;
                    color: white;
                    font-size: 1.5rem;
                    font-weight: bold;
                }
            }
        }

        .watch-later-list {
            .movie-tile {
                padding-left: $smaller-left-content-width;
    
                .left-content {
                    width: $smaller-left-content-width;
                }
            }
        }

        .empty-list-placeholder {
            text-align: center;
            margin: 1rem;
        }
    }

    // In small screen mode, everything is stacked and heights are auto
    @include smallScreen {
        height: auto;

        .upper {
            flex-direction: column;
            height: auto;
    
            .profile {
                width: 100%;
            }

            .suggestions {
                display: none;
            }
        }
    
        .lower {
            flex-direction: column;
            height: auto;

            .left, .right {
                height: auto;
                width: 100%;
            }

            .charts {
                border-top: solid 1px $gray;
                margin-bottom: 2rem;
            }
        }

        &.threshold {
            .sticky-header {
                position: fixed;
                top: $header-height;
                left: 0;
                right: 0;
            }

            .list-wrapper {
                padding-top: $sticky-header-height + 0.5rem;
            }
        }
    }
}