@import '../../../ConstantsAndMixins.scss';

$avatar-width: 100px;

.profile {
    display: flex;
    align-items: center;
    height: 100%;

    .profile-pic-wrapper {
        cursor: pointer;
    }

    // outside of wrapper for ViewList Page
    .profile-pic {
        height: $avatar-width;
        width: $avatar-width;
    }

    .profile-right {
        height: 100%;
        width: calc(100% - #{$avatar-width});
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding-left: 0.5rem;
        box-sizing: border-box;

        .name.display-mode {
            font-weight: bold;
            font-size: 1.2rem;

            @include smallScreen {
                font-size: 1rem;
            }
        }

        .buttons {
            display: flex;
            margin-top: 4px;
            margin-left: 1rem;

            @include smallScreen {
                justify-content: space-between;
            }

            button {
                @include buttonStyles;

                flex-grow: 1;
                margin-right: 1rem;
                max-width: 50%;

                &.disabled {
                    border-color: $light-gray;
                }

                @include smallScreen {
                    font-size: 0.7rem;
                    padding: 3px 6px;
                    margin-right: 0.5rem;
                }
            }

            .MuiBadge-badge {
                font-weight: bold;
            }
        }
    }
}