.modal {
    padding: 1rem;

    .modal-header {
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .MuiDialogTitle-root {
            padding: 0;
            margin-right: 1rem;

            h2 {
                font-weight: bold;
            }
        }

        .close-icon {
            margin: -12px;
        }
    }
}