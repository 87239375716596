@import '../../ConstantsAndMixins.scss';

$popup-margin: 1em;
$arrow-height: 1em;

.add-to-home-popup {
    position: fixed;
    bottom: $arrow-height;
    left: 1.1em;
    right: 1.1em;
    z-index: 1;
    background-color: $light-gray;
    padding: 0.5em 2.5em 0.8em 0.8em;
    border-radius: 10px;
    box-shadow: 2px 2px 9px 0px gray;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: $popup-font-size;
    background-color: black;
    color: white;

    @include fadeInAnimation;

    .popup-arrow {
        position: absolute;
        font-size: 7px;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        margin-bottom: -0.9em;
        width: 3 * $arrow-height;
        height: $arrow-height;
        z-index: 1;
    
        &::before {
            content: "";
            margin: auto;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 1em 1em 0 1em;
            border-color: black transparent transparent transparent;
        }
    }

    .app-icon {
        height: 6 * $popup-font-size;
        width: 6 * $popup-font-size;
        margin-right: 1em;
    }

    .instructions {
        .title {
            font-weight: 600;
            font-size: 1.2 * $popup-font-size;

            .wyl {
                font-weight: bold;
                font-size: 1.4 * $popup-font-size;
            }
        }

        .step {
            display: flex;
            align-items: center;
            margin-top: 0.5em;

            .bullet-point {
                margin-left: 0.5em;
                margin-right: 0.2em;
            }

            .share-icon {
                margin: 0 0.2em;
            }

            b {
                margin: 0 0.3em;
            }
        }
    }

    .close-icon-wrapper {
        position: absolute;
        top: 0.6em;
        right: 0.8em;
    }
}