@import '../../ConstantsAndMixins.scss';

.tabs-container {
    .MuiTabs-root {
        height: $tab-bar-height;
        min-height: $tab-bar-height;

        @include smallScreen {
            height: auto;
        }
    
        .MuiTab-root {
            min-height: $tab-bar-height;

            .MuiTab-wrapper {
                @include smallScreen {
                    font-size: 11px;
    
                    svg {
                        height: 1rem;
                        width: 1rem;
                    }
                }
            }
        }
    }

    .tab-panel {
        @include tabPanelStyles;

        height: calc(100% - #{$tab-bar-height});
    }
}