@import '../../ConstantsAndMixins.scss';

@mixin loginPage($logo-width, $font-size) {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    height: 100%;
    padding: 1rem;
    box-sizing: border-box;

    &.hide {
        #firebaseui_container {
            display: none;
        }
    }

    .landing-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 1rem;

        svg {
            width: $logo-width;
            height: $logo-width*0.3;
        }

        h2 {
            font-size: $font-size;
            text-align: center;
            margin: 1rem 0;
            color: white;
            font-weight: bold;
        }
    }

    .offline-disclaimer {
        color: white;
    }
}

.login-page {
    position: fixed;
    background: radial-gradient($dark-red, black);
    width: 100%;

    @include largeScreen {
        $logo-width: 575px;
        $font-size: 30px;

        @include loginPage($logo-width, $font-size);
    }

    @include smallScreen {
        $logo-width: 300px;
        $font-size: 20px;

        @include loginPage($logo-width, $font-size);

        flex-direction: column;
        justify-content: center;
    }
}

// Placing outside login-page for email verification page
.firebaseui-button {
    background: $yellow !important;
    color: black !important;
    font-family: 'Nunito' !important;

    &.firebaseui-id-secondary-link {
        background: $light-gray !important;
    }
}

.firebaseui-textfield.mdl-textfield .firebaseui-label:after {
    background-color: black !important;
}

.firebaseui-link {
    color: black !important;
}