@import '../../../ConstantsAndMixins.scss';

.charts {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    overflow: hidden; // Fixes scrollbar flickering issue
    height: 100%;
    width: 100%;

    &.disabled {
        @include disabledStyles;
    }

    @include smallScreen {
        height: 600px;

        &.disabled {
            display: none;
        }
    }
}

.custom-tooltip {
    background-color: rgba(255, 255, 255, 0.9);
    border: solid 2px black;
    border-radius: 5px;
    padding: 5px;
    font-size: 1rem;

    .genre-name {
        font-weight: bold;
        font-size: 1.1rem;
    }
}