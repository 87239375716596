@import '../../ConstantsAndMixins.scss';

.guide-tooltip {
    background-color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 10px;

    .action-button {
        margin-left: 0.5rem;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        margin-top: 1rem;
    }
}