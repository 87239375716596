@import '../../ConstantsAndMixins.scss';

.editable-label {
    &.edit-mode {
        display: flex;
        align-items: center;
        padding-left: 1rem;

        .MuiTextField-root {
            width: 100%;

            .MuiOutlinedInput-input {
                padding: 5px 10px;
            }
        }
    }

    &.display-mode {
        font-size: 18px;
        padding: 0.5rem 1rem;
        box-sizing: border-box;
        border-radius: 5px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 100%;
        width: fit-content;

        &.can-edit {
            cursor: pointer;

            &:hover {
                background-color: $light-gray;
            }
        }
    }
}