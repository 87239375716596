@import '../../ConstantsAndMixins.scss';

.movie-tile {
    @include tileStyles;

    position: relative;
    padding-left: $tile-left-content-width;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    height: 80px;

    // Give single digit ranks the biggest font size
    &.single .rank {
        font-size: 1.5rem;
    }

    // Give double digit ranks the middle font size
    &.double .rank {
        font-size: 1.25rem;
    }

    // Give triple digit ranks the smallest font size
    &.triple .rank {
        font-size: 1rem;
    }

    .left-content {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: $tile-left-content-width;
        padding-left: 0.5rem;
        padding-right: 1rem; // need to account for left border width to be symmetrical
        box-sizing: border-box;
        text-align: center;
        border-left: solid 0.5rem transparent;
        border-radius: 10px;

        > div {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;
        }
    }

    // Give top 10 movies a special left border color
    &.top-ten .left-content {
        border-left-color: $yellow;
    }

    .rank, .poster, .title {
        user-select: none;
    }

    .title {
        margin: auto 0; // We need vertical margin for share image centering
        font-size: 1.1rem;

        @include smallScreen {
            font-size: 1rem;
        }

        &.long {
            font-size: 0.8rem;
        }
    }

    .drag-handle {
        svg {
            padding-right: 0.25rem;
            color: $dark-gray;
        }

        &.hidden svg {
            display: none;
        }
    } 

    .rank {
        font-weight: bold;
        font-size: 20px;
        margin: auto;
    }

    .poster {
        max-height: 100%;
        max-width: 100%;
        margin-right: 1rem;
        border-radius: 8px;
    }

    .custom-menu {
        margin-left: auto;

        .MuiButton-root {
            border: solid 2px black;
            border-radius: 10px;
            padding: 0.25rem 0.5rem;
            font-weight: bold;
        }

        .MuiIconButton-root {
            color: black;
            padding: 4px;
        }
    }
}