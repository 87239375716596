@import './ConstantsAndMixins.scss';

// Setting heights: https://stackoverflow.com/a/17555766
html, #root {
    height: 100%;
}

body, .app {
    min-height: 100%;
    min-width: $small-screen-min;
    font-family: 'Nunito';
}

.app {
    width: 100%;
    padding-top: $header-height;
    box-sizing: border-box;
}

.MuiTooltip-tooltip {
    font-size: 16px !important;
}

body {
    margin: 0;
    background-color: $light-gray;
}

h1, h2 {
    margin: 0;
}