@import '../../ConstantsAndMixins.scss';

.view-list-page {
    button.send-request-button.disabled {
        max-width: 100%;
    }

    .view-list-container {
        padding: 1rem;
        box-sizing: border-box;

        .movie-tile {
            padding-left: $smaller-left-content-width;

            .left-content {
                width: $smaller-left-content-width;
            }
        }
    }
}