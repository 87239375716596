@import '../../../ConstantsAndMixins.scss';

.media-type-tabs {
    .tab-wrapper {
        display: flex;
        align-items: center;

        .tab-name {
            padding-left: $list-icon-padding;
        }
    }

    .MuiTab-wrapper {
        font-size: 0.9rem !important; // For these tabs we want to keep font-size large, even on small screens
    }

    .MuiTab-root {
        transition: all 300ms ease-in-out;
        transition-property: background-color, border-top, border-bottom, border-left, border-right;
        background-color: white;

        .MuiTab-wrapper {
            transition: color 300ms ease-in-out;
            color: $dark-gray;
        }

        &.Mui-selected {
            background-color: $light-gray;
            border-top: solid 2px black;

            &:first-child {
                border-right: solid 2px black;
                border-top-right-radius: 10px;
            }

            &:last-child {
                border-left: solid 2px black;
                border-top-left-radius: 10px;
            }

            .MuiTab-wrapper {
                color: black;
            }
        }

        &:not(.Mui-selected) {
            border-bottom: solid 2px black;
        }
    }

    .MuiTabs-indicator {
        display: none;
    }
}