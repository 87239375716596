@import '../../ConstantsAndMixins.scss';

.friends-page {
    height: 100%;
    width: 100%;

    .request, .friend {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include smallScreen {
            font-size: 12px;
        }

        .profile-info {
            display: flex;
            align-items: center;
            margin-right: 1rem;

            .profile-pic {
                margin-right: 1rem;
                height: 100px;
                width: 100px;

                @include smallScreen {
                    height: 50px;
                    width: 50px;
                }
            }
        }

        .button-section {
            display: flex;
            align-items: center;

            .remove-button {
                @include smallScreen {
                    display: none;
                }
            }

            .accept-button, .view-button {
                margin-left: 1rem;

                @include smallScreen {
                    font-size: 12px;
                    padding: 5px 10px;
                    line-height: unset;
                }
            }
        }

        @include tileStyles;
    }

    .MuiBadge-badge {
        font-weight: bold;
    }
}

.remove-modal {
    .button-section {
        .confirm-button {
            margin-left: 1rem;
        }
    }
}