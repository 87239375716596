$header-height: 50px;
$standard-shadow: 10px 5px 5px red;
$tab-bar-height: 40px;
$home-upper-height: 120px;
$list-icon-padding: 0.5rem;
$tile-left-content-width: 5.25rem;
$smaller-left-content-width: 4rem;
$popup-font-size: 0.9em;

// Colors
$light-gray: #f4f4f4; 
$gray: #c7c7c7;
$dark-gray: #616161;
$red: #C60000;
$dark-red: #9B0404;
$yellow: #FFF88B;

// Mixins
@mixin tileStyles {
    background-color: white;
    padding: 0.25rem 0.5rem;
    box-sizing: border-box;
    margin-bottom: 0.5rem;
    border: solid $gray 1px;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 $gray;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    font-size: 16px;

    @include smallScreen {
        font-size: 14px;
    }
}

@mixin buttonStyles {
    border-radius: 10px;
    border-width: 2px;
    border-color: black;
    font-family: 'Nunito';
    font-weight: bold;
    padding: 3px 6px;

    &.disabled {
        opacity: 0.5;
    }

    @include smallScreen {
        font-size: 0.8rem;
        margin-right: 0.5rem;
    }

    svg {
        margin-right: 7px;

        @include smallScreen {
            font-size: 1.1rem;
        }
    }
}

@mixin tabPanelStyles {
    padding: 1rem 1rem 0 1rem;
    box-sizing: border-box;
    width: 100%;
    background-color: $light-gray;
    height: 100%;

    @include smallScreen {
        height: auto;
        overflow: unset;
    }

    > * {
        width: 100%;
    }
}

@mixin disabledStyles {
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
}

@mixin fadeInAnimation($delay: 0) {
    @keyframes fadein {
        0% { opacity: 0; }
        100% { opacity: 1; }
    }

    -webkit-animation-name: fadein;
    -webkit-animation-duration: 0.75s;
    -webkit-animation-delay: $delay;
    -webkit-animation-fill-mode: forwards;

    animation-name: fadein;
    animation-duration: 0.75s;
    animation-delay: $delay;
    animation-fill-mode: forwards
}

$small-screen-min: 325px;
$small-screen-max: 900px;

@mixin smallScreen {
    @media screen and (max-width: $small-screen-max) { @content; }
}

@mixin largeScreen {
    @media screen and (min-width: ($small-screen-max + 1px)) { @content; }
}