@import '../../../ConstantsAndMixins.scss';

.find-friends {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .instructions {
        margin-bottom: 0.5rem;
        text-align: center;
    }

    .upper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        .search-box {
            width: 50%;
            margin-right: 0.5rem;
            background-color: white;
        }
    }

    .num-results {
        margin-top: 1rem;
    }

    .search-results {
        margin-top: 1rem;
        height: 100%;
        width: 100%;
        overflow: auto;

        .result {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            @include tileStyles;

            .profile-info {
                display: flex;
                align-items: center;

                .profile-pic {
                    margin-right: 1rem;
                    height: 75px;
                    width: 75px;
                }

                .name {
                    margin-right: 1rem;
                }
            }

            .add-button {
                align-content: flex-end;

                @include smallScreen {
                    font-size: 12px;
                }
            }

            .sent-message {
                margin: 0 1rem;
            }
        }
    }
}