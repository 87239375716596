@import '../../../ConstantsAndMixins.scss';

.suggestions {
    margin-left: 1rem;
    padding: 0 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;

    .suggestions-overlay {
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .try-again-button {
            margin-top: 0.5rem;
        }
    }

    .section-title {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 0.25rem;
    }

    .carousel {
        height: 100%;
        width: 100%;

        .react-multiple-carousel__arrow--left {
            left: 0;
        }

        .react-multiple-carousel__arrow--right {
            right: 0;
        }

        .react-multi-carousel-track {
            height: 100%;

            .suggestion {
                height: 100%;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    .poster {
                        opacity: 0.5;
                    }

                    .hover-overlay {
                        opacity: 1;
                    }
                }

                .poster {
                    max-height: 100%;
                    width: 100px;
                    border-radius: 8px;
                    object-fit: contain;
                }

                .hover-overlay {
                    cursor: pointer;
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .add-icon {
                        font-size: 2rem;
                        color: black;
                    }
                }
            }
        }
    }
}